import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

import styles from './TextInputControl.module.scss';
import InputControlLabel from './InputControlLabel';
import { colorSchemes, emailValidationPattern } from '../constants';
import { controlTightness, orientationOptions } from './control_component_constants';
import InputHelper from './InputHelper';

export const textInputColorSchemes = { ...colorSchemes };
export const textInputControlTightness = { ...controlTightness };
export const textInputOrientationOptions = { ...orientationOptions };

export const inputTypes = {
    TEXT: 'text',
    PASSWORD: 'password',
    EMAIL: 'email',
    NUMBER: 'number',
};

export default function TextInputControl({
    colorScheme = textInputColorSchemes.LIGHT,
    type = inputTypes.TEXT,
    value = '',
    placeholder = undefined,
    onChange = () => {},
    onEnter = undefined,
    label = undefined,
    helperText = undefined,
    tightness = controlTightness.LOOSE,
    orientation = orientationOptions.STACKED,
    numericValuesOnly = false,
}) {
    const inputId = uuidv4();
    const [inputValue, setInputValue] = useState(value);

    function handleChange(event) {
        setInputValue(event.target.value);
        onChange(event.target.value);
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter' && onEnter) {
            event.preventDefault();
            event.target.blur();
        }

        if (event.key === 'Escape') {
            setInputValue(value || '');
            onChange(value || '');
            event.target.blur();
        }
        if (numericValuesOnly) {
            if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
                event.preventDefault(); // Prevent input for non-numeric keys
            }
        }
    }

    function handleBlur() {
        if (onEnter) {
            onEnter(inputValue);
        }
    }

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <div className={classNames(styles.layout, styles[orientation])}>
            {label && <InputControlLabel text={label} colorScheme={colorScheme} />}
            <input
                id={inputId}
                type={type}
                pattern={type === inputTypes.EMAIL ? emailValidationPattern : undefined}
                name="text"
                className={classNames(styles.input, styles[colorScheme], styles[tightness])}
                placeholder={placeholder}
                onKeyDown={(event) => handleKeyDown(event)}
                onChange={handleChange}
                onBlur={() => handleBlur()}
                value={inputValue}
            />
            {helperText && <InputHelper text={helperText} colorScheme={colorScheme} />}
        </div>
    );
}

TextInputControl.propTypes = {
    colorScheme: PropTypes.oneOf(Object.values(textInputColorSchemes)),
    type: PropTypes.oneOf(Object.values(inputTypes)),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    label: PropTypes.string,
    helperText: PropTypes.string,
    tightness: PropTypes.oneOf(Object.values(controlTightness)),
    orientation: PropTypes.oneOf(Object.values(orientationOptions)),
    numericValuesOnly: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './icon/Icon';
import { textColorNames, iconNames } from './constants';

import styles from './TextLink.module.scss';

const iconSizes = {
    small: 16,
    medium: 20,
    large: 24,
};

export const textLinkSizes = {
    SMALL: 'small', // 12px
    MEDIUM: 'medium', // 14px
    LARGE: 'large', // 16px
    XLARGE: 'xlarge', // 24px
};

export default function TextLink({
    children,
    size = textLinkSizes.MEDIUM,
    color = textColorNames.actionDark,
    icon = undefined,
    iconSize = undefined,
    onClick = () => false,
    disabled = false,
    valueUnset = false,
}) {
    return (
        <button
            type="button"
            className={classNames(
                styles.textLink,
                styles[color],
                styles[size],
                valueUnset && styles.valueUnset,
                icon && styles.withIcon,
                disabled && styles.disabled
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {icon && <Icon name={icon} size={iconSize || iconSizes[size]} color={color} />}
            {children}
        </button>
    );
}
TextLink.propTypes = {
    size: PropTypes.oneOf(Object.values(textLinkSizes)),
    color: PropTypes.oneOf(Object.values(textColorNames)),
    icon: PropTypes.oneOf(Object.values(iconNames)),
    iconSize: PropTypes.oneOf(Object.values(iconSizes)),
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    valueUnset: PropTypes.bool,
};
